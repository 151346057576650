/* global jQuery */

jQuery(function($) {


	// PROFILE ADDITIONAL PAGES

		// Show relevant page on click
		$( '.single-profiles #biography-link' ).click( function() {
			$( '.single-profiles .profile-page' ).hide();
			$( '.single-profiles .additional-page-link' ).removeClass( 'active' );
			$( '.single-profiles #biography' ).show();
			$( this ).addClass( 'active' );
		});
		$( '.single-profiles #additional-page-1-link' ).click( function() {
			$( '.single-profiles .profile-page' ).hide();
			$( '.single-profiles .additional-page-link' ).removeClass( 'active' );
			$( '.single-profiles #additional-page-1' ).show();
			$( this ).addClass( 'active' );
		});
		$( '.single-profiles #additional-page-2-link' ).click( function() {
			$( '.single-profiles .profile-page' ).hide();
			$( '.single-profiles .additional-page-link' ).removeClass( 'active' );
			$( '.single-profiles #additional-page-2' ).show();
			$( this ).addClass( 'active' );
		});
		$( '.single-profiles #additional-page-3-link' ).click( function() {
			$( '.single-profiles .profile-page' ).hide();
			$( '.single-profiles .additional-page-link' ).removeClass( 'active' );
			$( '.single-profiles #additional-page-3' ).show();
			$( this ).addClass( 'active' );
		});
		$( '.single-profiles #additional-page-4-link' ).click( function() {
			$( '.single-profiles .profile-page' ).hide();
			$( '.single-profiles .additional-page-link' ).removeClass( 'active' );
			$( '.single-profiles #additional-page-4' ).show();
			$( this ).addClass( 'active' );
		});
		$( '.single-profiles #additional-page-5-link' ).click( function() {
			$( '.single-profiles .profile-page' ).hide();
			$( '.single-profiles .additional-page-link' ).removeClass( 'active' );
			$( '.single-profiles #additional-page-5' ).show();
			$( this ).addClass( 'active' );
		});
		$( '.single-profiles #additional-page-6-link' ).click( function() {
			$( '.single-profiles .profile-page' ).hide();
			$( '.single-profiles .additional-page-link' ).removeClass( 'active' );
			$( '.single-profiles #additional-page-6' ).show();
			$( this ).addClass( 'active' );
		});
		$( '.single-profiles #additional-page-7-link' ).click( function() {
			$( '.single-profiles .profile-page' ).hide();
			$( '.single-profiles .additional-page-link' ).removeClass( 'active' );
			$( '.single-profiles #additional-page-7' ).show();
			$( this ).addClass( 'active' );
		});
		$( '.single-profiles #additional-page-8-link' ).click( function() {
			$( '.single-profiles .profile-page' ).hide();
			$( '.single-profiles .additional-page-link' ).removeClass( 'active' );
			$( '.single-profiles #additional-page-8' ).show();
			$( this ).addClass( 'active' );
		});
		$( '.single-profiles #additional-page-9-link' ).click( function() {
			$( '.single-profiles .profile-page' ).hide();
			$( '.single-profiles .additional-page-link' ).removeClass( 'active' );
			$( '.single-profiles #additional-page-9' ).show();
			$( this ).addClass( 'active' );
		});
		$( '.single-profiles #additional-page-10-link' ).click( function() {
			$( '.single-profiles .profile-page' ).hide();
			$( '.single-profiles .additional-page-link' ).removeClass( 'active' );
			$( '.single-profiles #additional-page-10' ).show();
			$( this ).addClass( 'active' );
		});


	// MOBILE HAMBURGER

		// Show when hamburger is tapped
		$( "#mobile-hamburger" ).click( function() {

			$( "#menu-header" ).slideToggle( 300 );

		});


	// RESPONSIVE VIDEO

		// Find all videos
		var allVideos = $('iframe.oembed, .oembed-wrapper iframe');

		// Figure out and save aspect ratio for each video
		allVideos.each(function() {

			var video = $(this);

			$(this)
				.data('aspectRatio', this.height / this.width)
				.data('videoParent', video.parent())

				// and remove the hard coded width/height
				.removeAttr('height')
				.removeAttr('width');

		});

		// Resize function
		function resizeNow() {

			// Resize all videos according to their own aspect ratio
			allVideos.each(function() {

				var video = $(this);

				var newWidth = video.data('videoParent').width();

				video
					.width(newWidth)
					.height(newWidth * video.data('aspectRatio'));

			});

		}

		// Trigger on window resize and kick off one resize to fix all videos on page load
		$(window) .resize(function() { resizeNow(); }) .resize();

		// Cope with videos in Easytabs
		$('#tab-container').bind('easytabs:after', function(e) { e.preventDefault(); resizeNow(); });

});
